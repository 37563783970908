.login {
  display: flex;
  height: 100vh;
  &__content {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__form {
      width: 300px;
      &__logo {
        width: 150px;
      }
      &__title {
        margin: 15px 0;
        font-size: 18px;
        font-weight: 500;
      }
      &__body {
        label {
          font-size: 12px;
        }
        button {
          width: 100%;
        }
      }
    }
  }
  &__sample {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--app-light-color);
    img {
      height: 100%;
    }
  }
}

@media (max-width: 767.98px) {
  .login {
    &__content {
      width: 100%;
    }
    &__sample {
      display: none;
    }
  }
}