.shop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;

  &__body {
    margin-top: 3.6em;

    &__cont {
      
      &__title {
        padding: .4em;
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
        border-radius: .5em;
        text-align: center;
        font-size: 1.5em;
        font-weight: bold;
        color: #1890ff;
      }
    }
  }
}

.add__box{
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  &__btn{
    border: none;
    color: white;
    width: 2em;
    height: 2em;
    outline: none;
    border-radius: 50%;
    background-color: #1890ff;
    transition: all 0.5s;

    &:active{
      background-color: #8fc4ee;
    }s

    &__icon{
      font-size: 2em;
      color: white;
    }
  }

  &__input {
    border-radius: 1em;
    border: 1px solid #1890ff;
    outline: none;
    text-align: center;
    padding: 0 1em;
    font-size: 1.2em;
    font-weight: bold;
  }
}

.ant-card {
  width: 90vw;
  margin-top: 2em;
  border-radius: 1em;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
  transition: all 0.5s;

  &:hover {
    box-shadow: 0 0 .5em rgba(0, 0, 0, 0.5);
  }
}
