.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.5s all;
    z-index: -1;
    top: 0;
    &-show {
        opacity: 1;
        z-index: 100000;
    }
    &__spinner {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        div {
            position: absolute;
            border: 4px solid var(--app-primary-color);
            opacity: 1;
            border-radius: 50%;
            animation: spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }
        div:nth-child(2) {
            animation-delay: -0.5s;
        }
    }
}
@keyframes spinner {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}