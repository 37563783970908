.list-order {
    &__item {
        margin-bottom: 5px;

        &__name {
            font-weight: 400;
        }

        &__desc {
            font-weight: 200;
        }
    }
}

.order__header__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px var(--app-primary-color) solid;
    width: 100%;
}

.form-control{
    &-small {
        width:75px;
    }
}

.btn {
    z-index: 0;
    border: var(--app-primary-color) 1px solid;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.8;
    }
}

.navbar__btn {
    &__green {
        background: #17A03C;
        border: #17A03C 1px solid !important;
        transition: all 0.3s ease;
        border-radius: 5px;
        color: white;

        &:hover {
           background: white;
           color: #17A03C;
        }

        &:active {
            background: #17A03C;
            color: white;
        }
    }

    &__red {
        background: #F44336;
        border: #F44336 1px solid !important;
        transition: all 0.3s ease;
        border-radius: 5px;
        color: white;
        margin-left: 10px;
        padding: 1px 9px 1px 9px;

        &:hover {
            background: white;
            color: #F44336;

        }
        
        &active {
            background: #F44336;
            color: white;
        }
    }

    &__yellow {
        background: #FFC107;
        border: #FFC107 1px solid !important;

        &:hover {
            color: #FFC107;
        }
    }
}

.link {
    color: var(--app-primary-color);
    text-decoration: none;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
}