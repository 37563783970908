.product__image{
    width: 80px;
    height:80px;
    border-radius: 10em;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    margin: 0;
}