.dashboard {
    h1 {
        font-weight: 900;
        font-size: 60px;
        line-height: 60px;
        color: var(--app-primary-color);
    }
    h4 {
        font-weight: 200;
    }
    img {
        width: 30%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media (max-width: 767.98px) {
    .dashboard {
        img {
            display: none;
        }
    }
}