.cont{
    display: flex;
    justify-content: space-between;
    padding: .8em 0;

    .content-window{
        width: 29%;
        padding: 1em;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        border-radius: 1em;
    }

    .title{
        font-size: 1.5em;
        font-weight: 600;
        text-align: center;
        background: var(--app-light-color);
        border-radius: .5em ;
        padding: 10px;
    }

    .orders-route{
        padding: 1em;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 1em;
    }

    .date-style{
        margin-top: 1em;
        width: 100%;
    }
}


.map-div{
    display: flex;
    align-items: center;
    width: 70%;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    position: relative;
}